import { defaultOperationDetail } from '../model/OperationDetail';
import { SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react';
import { Context } from '../utils/context';
import { useNavigate, useParams } from 'react-router-dom';
import { getOperationDetail, listGoals, updateOperationDetail } from '../utils/api';
import Loading from '../components/Loading';
import Select from '../components/Select';

export default function OperationDetailFull(): JSX.Element {
    const { client, operationDetails, setConfirmDialog } = useContext(Context);
    const [loading, setLoading] = useState(true);
    const { operation_detail_id } = useParams();
    const [operationDetail, setOperationDetail] = useState({ ...defaultOperationDetail });
    const [goalsOptions, setGoalsOptions] = useState<{ value?: string, alias?: string }[]>([]);
    const navigate = useNavigate();

    const setUp = useCallback(async () => {
        try {
            setLoading(true);
            if (!operation_detail_id) {
                navigate('/profile');
                return;
            }
            listGoals(client.id ?? '').then(goals => setGoalsOptions(goals
                .filter(goal => !operationDetails.map(od => od.goal_id).includes(goal.goal_id))
                .map(goal => ({ value: goal.goal_id, alias: goal.title }))
            ));
            const results = await getOperationDetail(client.id ?? '', operation_detail_id);

            setOperationDetail({ ...results });
        } finally {
            setLoading(false);
        }
    }, [operation_detail_id, navigate, client.id, operationDetails]);

    useEffect(() => { setUp() }, [setUp]);

    function handleInputChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        setOperationDetail(oldOperationDetail => ({
            ...oldOperationDetail,
            [target.name]: target.value
        }));
    }

    function saveChanges() {
        setLoading(true);
        updateOperationDetail(client.id ?? '', operationDetail.id ?? '', operationDetail).then(() => {
            navigate('/profile');
        }).finally(() => {
            setLoading(false);
        });
    }

    async function confirmOperationDetailUpdate(status: string) {
        setLoading(true);
        try {
            await updateOperationDetail(client.id ?? '', operationDetail.id ?? '', { ...operationDetail, status });
        } finally {
            setUp();
        }
    }

    const confirmDisableDialogParams = {
        callback: () => confirmOperationDetailUpdate('DISABLED'),
        title: '¿Estás seguro que deseas deshabiltiar esta categoría de movimiento?',
        confirmButton: 'Deshabilitar',
        cancelButton: 'Cancelar'
    };

    const confirmEnableDialogParams = {
        callback: () => confirmOperationDetailUpdate('ENABLED'),
        title: '¿Estás seguro que deseas habiltiar esta categoría de movimiento?',
        confirmButton: 'Habilitar',
        cancelButton: 'Cancelar'
    };

    if (loading) return <Loading />

    return (
        <main>
            <button className='button-aux w-fit p-1 col-span-full' onClick={() => navigate(`/profile`)}>
                <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 mt-0.5 h-2.5 w-2.5" viewBox="0 0 24 24"><path fill="currentColor" d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875q-.375.375-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12q0-.375.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388q.375.375.375.875t-.375.875L3.55 12Z" /></svg>
                <div>Volver al listado</div>
            </button>
            <article className='bg-sf-violet-light'>
                <div className='flex w-full justify-between items-center h-min'>
                    <h2>Editar categoría de movimiento</h2>
                    {operationDetail.type === 'INCOME' &&
                        <small className="ring-1 ring-inset ring-sf-green-dark  h-min px-2.5 py-0.5 rounded-full flex justify-center items-center text-sf-green-dark font-bold whitespace-nowrap">
                            Ingreso
                        </small>
                    }
                    {operationDetail.type === 'EXPENDITURE' &&
                        <small className="ring-1 ring-inset ring-sf-red-dark  h-min px-2.5 py-0.5 rounded-full flex justify-center items-center text-sf-red-dark font-bold whitespace-nowrap">
                            Egreso
                        </small>
                    }
                </div>
                <div className='border-t border-black w-full h-min pb-1'></div>
                <input name='title' type='text' placeholder='Nombre *' value={operationDetail.title} onChange={handleInputChange} className='field' />
                <input name='description' type='text' placeholder='Descripción (opcional)' value={operationDetail.description} onChange={handleInputChange} className='field' />
                {operationDetail.type === 'EXPENDITURE' && goalsOptions.length > 0 &&
                    <article className='bg-sf-violet-medium'>
                        <Select placeholder='Selecciona un objetivo *' name='goal_id' cleanable value={operationDetail.goal_id} onChange={handleInputChange} options={goalsOptions} />
                        <h3 className='underline'>Advertencia: después de seleccionar un objetivo y guardar, no podrá modificarse la selección.</h3>
                    </article>
                }
                <div className='self-end flex space-x-2 w-full'>
                    <div className='w-full min-h-full'>
                        {operationDetail.status === 'ENABLED' ?
                            <button className="h-full button-secondary-red" onClick={() => setConfirmDialog(confirmDisableDialogParams)}>
                                Deshabilitar
                            </button>
                            :
                            <button className="h-full button-secondary" onClick={() => setConfirmDialog(confirmEnableDialogParams)}>
                                Habilitar
                            </button>
                        }
                    </div>
                    <button className="button-primary" disabled={!operationDetail.title || !operationDetail.status || !operationDetail.type || !operationDetail.status} onClick={saveChanges}>
                        Guardar
                    </button>
                </div>
            </article>
        </main>
    )
}