export interface Employee {
    readonly 'id'?: string;
    readonly 'name'?: string;
    readonly 'email'?: string;
    readonly 'type'?: string;
};

export const defaultEmployee: Employee = {
    'id': '',
    'name': '',
    'email': '',
    'type': ''
};
