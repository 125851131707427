import { Currency } from "./Currency";

export interface Client {
    readonly 'currencies'?: Currency[];
    readonly 'id'?: string;
    readonly 'name'?: string;
    readonly 'status'?: string;
    readonly 'email'?: string;
    readonly 'enable_plan'?: boolean;
    readonly 'identity_id'?: string;
    readonly 'cognito_status'?: string;
    readonly 'creation_timestamp'?: string;
    readonly 'update_timestamp'?: string;
};

export const defaultClient: Client = {
    'currencies': [],
    'id': '',
    'name': '',
    'status': '',
    'email': '',
    'enable_plan': false,
    'identity_id': '',
    'cognito_status': '',
    'creation_timestamp': '',
    'update_timestamp': ''
};
