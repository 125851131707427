import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import logo from '../images/somosfin_logo.svg';
import { handleSignOut } from '../utils/handle-sign-out';
import { Context } from '../utils/context';
import ConfirmDialog from '../components/ConfirmDialog';
import { regexs } from '../consts/protected-paths';
import { getClient, listFinancialElements, listOperationDetails, getEmployee, listClients } from '../utils/api';
import Loading from '../components/Loading';
import { useErrorBoundary } from 'react-error-boundary';
import { addMonthsToTimestamp } from '../utils/date-utils';
import OperationsService from '../utils/OperationsService';

export default function SharedLayout(): JSX.Element {
    const { employee, feedbacks, removeFeedback, setConfirmDialog, userId, client, setClient, setFinancialElements, setOperationDetails, setEmployee } = useContext(Context);
    const [isContentBlocked, setIsContentBlocked] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const { showBoundary } = useErrorBoundary();

    useEffect(() => {
        const setUp = async () => {
            const timestamp = new Date().getTime();
            try {
                setLoading(true);
                const employee = await getEmployee(userId);
                setEmployee(employee);
                let clientId = window.localStorage.getItem('client_id');
                if (!clientId) {
                    const clients = await listClients();
                    clientId = clients[0]?.id ?? '';
                    window.localStorage.setItem('client_id', clientId);
                }
                if (!clientId) {
                    throw new Error('No client found.');
                }
                const today = new Date();
                const UTCtoday = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()).toString();
                await Promise.all([
                    getClient(clientId).then(setClient),
                    OperationsService.listOperationsByRange(clientId, addMonthsToTimestamp(UTCtoday, -3), addMonthsToTimestamp(UTCtoday, 1)),
                    listFinancialElements(clientId).then(setFinancialElements),
                    listOperationDetails(clientId).then(setOperationDetails)
                ]);
            } catch (error) {
                showBoundary({ error: JSON.stringify(error), errorWithReplacer: JSON.stringify(error, Object.getOwnPropertyNames(error)), timestamp, userId });
            } finally {
                setLoading(false);
            }
        };
        setUp();
    }, [userId, showBoundary, setClient, setEmployee, setFinancialElements, setOperationDetails]);

    if (loading) return <main className='fallback-page'><Loading /></main>

    function goTo(path: string) {
        const navigateToPath = async () => {
            navigate(path);
            setIsContentBlocked(false);
        };
        if (regexs.find(regex => location.pathname.match(regex) !== null)) {
            setConfirmDialog({ callback: navigateToPath, title: 'Advertencia: Pueden perderse cambios', cancelButton: 'Cancelar', confirmButton: 'Confirmar' })
        } else {
            navigateToPath();
        }
    }

    return (
        <div>

            <div className={`z-20 fixed w-full h-full bg-sf-black opacity-30 ${!isContentBlocked && 'hidden'}`} onClick={() => setIsContentBlocked(oldIsOpen => !oldIsOpen)} />

            <aside className={`z-30 fixed bg-sf-white w-64 h-full transition-transform lg:translate-x-0 overflow-y-auto overflow-x-hidden ${!isContentBlocked && '-translate-x-full'}`}>
                <div className="flex flex-col justify-between h-full">
                    <div>
                        <div className='w-full flex justify-center py-8'>
                            <img src={logo} alt='logo' className='h-8 antialiased cursor-pointer' onClick={() => goTo('/')} />
                        </div>
                        <div className='flex justify-start mx-4'>
                            <small>MOVIMIENTOS</small>
                        </div>
                        <div onClick={() => goTo('/operations/new')} className={`p-2 my-1 flex items-center cursor-pointer ${location.pathname.match('/operations/new') ? 'bg-sf-gray-light' : 'hover:bg-sf-gray-extra-light'}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-5 mx-4' viewBox="2 2 20 22" fill="none">
                                <path fill="currentColor" d="M11 13H6q-.425 0-.713-.288T5 12q0-.425.288-.713T6 11h5V6q0-.425.288-.713T12 5q.425 0 .713.288T13 6v5h5q.425 0 .713.288T19 12q0 .425-.288.713T18 13h-5v5q0 .425-.288.713T12 19q-.425 0-.713-.288T11 18v-5Z" />
                            </svg>
                            <h2 className="text-lg my-1">Nuevo movimiento</h2>
                        </div>
                        <div onClick={() => goTo('/operations')} className={`p-2 my-1 flex items-center cursor-pointer ${(location.pathname.match('/operations') && !location.pathname.match('/operations/new')) ? 'bg-sf-gray-light' : 'hover:bg-sf-gray-extra-light'}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-5 mx-4' viewBox="2 2 20 22" fill="none">
                                <path d="M9 3C9.2198 2.99998 9.43348 3.07238 9.608 3.206L9.708 3.293L12.414 6H19C19.7652 5.99996 20.5015 6.29233 21.0583 6.81728C21.615 7.34224 21.9501 8.06011 21.995 8.824L22 9V17C22 17.7652 21.7077 18.5015 21.1827 19.0583C20.6578 19.615 19.9399 19.9501 19.176 19.995L19 20H5C4.23479 20 3.49849 19.7077 2.94174 19.1827C2.38499 18.6578 2.04989 17.9399 2.005 17.176L2 17V6C1.99996 5.23479 2.29233 4.49849 2.81728 3.94174C3.34224 3.38499 4.06011 3.04989 4.824 3.005L5 3H9Z" fill="#917BE8" />
                            </svg>
                            <h2 className="text-lg my-1">Ingresos y egresos</h2>
                        </div>
                        <div onClick={() => goTo('/budgets')} className={`p-2 my-1 flex items-center cursor-pointer ${location.pathname.match('/budgets') ? 'bg-sf-gray-light' : 'hover:bg-sf-gray-extra-light'}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-5 mx-4' viewBox="2 -2 20 22" fill="none">
                                <path d="M0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H19C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1C20 1.26522 19.8946 1.51957 19.7071 1.70711C19.5196 1.89464 19.2652 2 19 2H1C0.734784 2 0.48043 1.89464 0.292893 1.70711C0.105357 1.51957 0 1.26522 0 1ZM0 15C0 14.7348 0.105357 14.4804 0.292893 14.2929C0.48043 14.1054 0.734784 14 1 14H17C17.2652 14 17.5196 14.1054 17.7071 14.2929C17.8946 14.4804 18 14.7348 18 15C18 15.2652 17.8946 15.5196 17.7071 15.7071C17.5196 15.8946 17.2652 16 17 16H1C0.734784 16 0.48043 15.8946 0.292893 15.7071C0.105357 15.5196 0 15.2652 0 15ZM1 7C0.734784 7 0.48043 7.10536 0.292893 7.29289C0.105357 7.48043 0 7.73478 0 8C0 8.26522 0.105357 8.51957 0.292893 8.70711C0.48043 8.89464 0.734784 9 1 9H21C21.2652 9 21.5196 8.89464 21.7071 8.70711C21.8946 8.51957 22 8.26522 22 8C22 7.73478 21.8946 7.48043 21.7071 7.29289C21.5196 7.10536 21.2652 7 21 7H1Z" fill="black" />
                            </svg>
                            <h2 className="text-lg my-1">Tablero de control</h2>
                        </div>
                        {client?.enable_plan && <>
                            <div onClick={() => goTo('/cashflow')} className={`p-2 my-1 flex items-center cursor-pointer ${location.pathname.match('/cashflow') ? 'bg-sf-gray-light' : 'hover:bg-sf-gray-extra-light'}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" className='w-5 mx-4' viewBox="2 0 20 22">
                                    <path d="M4 19q-.825 0-1.413-.588T2 17V7q0-.825.588-1.413T4 5h16q.825 0 1.413.588T22 7v10q0 .825-.588 1.413T20 19H4Zm13.5-4.5h-1.75q-.325 0-.537.213T15 15.25q0 .325.213.537t.537.213H18q.425 0 .713-.288T19 15v-2.25q0-.325-.213-.537T18.25 12q-.325 0-.537.213t-.213.537v1.75ZM12 15q1.25 0 2.125-.875T15 12q0-1.25-.875-2.125T12 9q-1.25 0-2.125.875T9 12q0 1.25.875 2.125T12 15ZM6.5 9.5h1.75q.325 0 .537-.213T9 8.75q0-.325-.213-.537T8.25 8H6q-.425 0-.713.288T5 9v2.25q0 .325.213.537T5.75 12q.325 0 .537-.213t.213-.537V9.5Z" fill="#66CC66" />
                                </svg>
                                <h2 className="text-lg my-1">Análisis de efectivo</h2>
                            </div>

                            {!isContentBlocked && <>
                                <div className='flex justify-start m-4 mb-0'>
                                    <small>PLAN</small>
                                </div>

                                <div onClick={() => goTo('/plan')} className={`p-2 my-1 flex items-center cursor-pointer ${location.pathname.match('/plan') ? 'bg-sf-gray-light' : 'hover:bg-sf-gray-extra-light'}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className='w-5 mx-4' viewBox="2 0 20 22">
                                        <path fill="#61A9FF" d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2M9 17H7v-7h2zm4 0h-2V7h2zm4 0h-2v-4h2z" />
                                    </svg>
                                    <h2 className="text-lg my-1">Plan mensual</h2>
                                </div>
                                <div onClick={() => goTo('/yearplans')} className={`p-2 my-1 flex items-center cursor-pointer ${location.pathname.match('/yearplans') ? 'bg-sf-gray-light' : 'hover:bg-sf-gray-extra-light'}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className='w-5 mx-4' viewBox="2 0 20 22">
                                        <path fill="#61A9FF" d="M4 20h12v2H4c-1.1 0-2-.9-2-2V7h2m18-3v12c0 1.1-.9 2-2 2H8c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h12c1.1 0 2 .9 2 2M12 8h-2v6h2m3-8h-2v8h2m3-3h-2v3h2Z" />
                                    </svg>
                                    <h2 className="text-lg my-1">Plan anual</h2>
                                </div>
                            </>}
                        </>}

                        <div className='flex justify-start m-4 mb-0'>
                            <small>MI INFORMACIÓN</small>
                        </div>

                        <div onClick={() => goTo('/goals')} className={`p-2 my-1 flex items-center cursor-pointer ${location.pathname.match('/goals') ? 'bg-sf-gray-light' : 'hover:bg-sf-gray-extra-light'}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-5 mx-4' viewBox="-1 -3 20 22" fill="none">
                                <path d="M15.3738 0.0546875L7.12617 10.9285L2.25 6.05586L0 8.30586L7.49883 15.8047L18 2.30469L15.3738 0.0546875Z" fill="#66CC66" />
                            </svg>
                            <h2 className="text-lg my-1">Objetivos</h2>
                        </div>
                        <div onClick={() => goTo('/assets')} className={`p-2 my-1 flex items-center cursor-pointer ${location.pathname.match('/assets') ? 'bg-sf-gray-light' : 'hover:bg-sf-gray-extra-light'}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-5 mx-4' viewBox="0 0 20 22" fill="none">
                                <path d="M18 0.199951H15.6C15.2817 0.199951 14.9765 0.326379 14.7515 0.551423C14.5264 0.776467 14.4 1.08169 14.4 1.39995V21.7904H19.2V1.39995C19.2 1.08169 19.0736 0.776467 18.8485 0.551423C18.6235 0.326379 18.3183 0.199951 18 0.199951ZM10.8 7.39995H8.4C8.08174 7.39995 7.77652 7.52638 7.55147 7.75142C7.32643 7.97647 7.2 8.28169 7.2 8.59995V21.7904H12V8.59995C12 8.28169 11.8736 7.97647 11.6485 7.75142C11.4235 7.52638 11.1183 7.39995 10.8 7.39995ZM3.6 14.6H1.2C0.88174 14.6 0.576515 14.7264 0.351472 14.9514C0.126428 15.1765 0 15.4817 0 15.8V21.7904H4.8V15.8C4.8 15.4817 4.67357 15.1765 4.44853 14.9514C4.22348 14.7264 3.91826 14.6 3.6 14.6Z" fill="#FFC03F" />
                            </svg>
                            <h2 className="text-lg my-1">Ahorros e inversiones</h2>
                        </div>
                        <div onClick={() => goTo('/debts')} className={`p-2 my-1 flex items-center cursor-pointer ${location.pathname.match('/debts') ? 'bg-sf-gray-light' : 'hover:bg-sf-gray-extra-light'}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-5 mx-4' viewBox="-1 -3 20 22" fill="none">
                                <path d="M5.00016 0H11.0002V8H15.8402L8.00016 15.84L0.160156 8H5.00016V0Z" fill="#FF584A" />
                            </svg>
                            <h2 className="text-lg my-1">Deudas</h2>
                        </div>
                        <div onClick={() => goTo('/profile')} className={`p-2 my-1 flex items-center cursor-pointer ${location.pathname.match('/profile') ? 'bg-sf-gray-light' : 'hover:bg-sf-gray-extra-light'}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-5 mx-4' viewBox="0 0 20 22" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M5 4C5 2.93913 5.42143 1.92172 6.17157 1.17157C6.92172 0.421427 7.93913 0 9 0C10.0609 0 11.0783 0.421427 11.8284 1.17157C12.5786 1.92172 13 2.93913 13 4C13 5.06087 12.5786 6.07828 11.8284 6.82843C11.0783 7.57857 10.0609 8 9 8C7.93913 8 6.92172 7.57857 6.17157 6.82843C5.42143 6.07828 5 5.06087 5 4ZM5 10C3.67392 10 2.40215 10.5268 1.46447 11.4645C0.526784 12.4021 0 13.6739 0 15C0 15.7956 0.316071 16.5587 0.87868 17.1213C1.44129 17.6839 2.20435 18 3 18H15C15.7956 18 16.5587 17.6839 17.1213 17.1213C17.6839 16.5587 18 15.7956 18 15C18 13.6739 17.4732 12.4021 16.5355 11.4645C15.5979 10.5268 14.3261 10 13 10H5Z" fill="#1C1C1A" />
                            </svg>
                            <h2 className="text-lg my-1">Perfil</h2>
                        </div>
                    </div>

                    <div>
                        <div onClick={() => goTo('/clients')} className='m-2 py-2 bg-sf-gray-extra-light flex flex-col items-center justify-center ring-inset ring-sf-gray-dark rounded-lg hover:ring-1 cursor-pointer'>
                            <p className='font-bold'>Seleccionar clientes</p>
                            <small>{client.name}</small>
                            <small>{client.email}</small>
                        </div>
                        {employee.type === 'manager' && <div onClick={() => goTo('/management')} className='m-2 py-2 bg-sf-gray-extra-light flex items-center justify-center ring-inset ring-sf-gray-dark rounded-lg hover:ring-1 cursor-pointer'>
                            <p className='font-bold'>Administrar usuarios</p>
                        </div>}
                        {employee.type === 'manager' && <div onClick={() => goTo('/exchange_rates')} className='m-2 py-2 bg-sf-gray-extra-light flex items-center justify-center ring-inset ring-sf-gray-dark rounded-lg hover:ring-1 cursor-pointer'>
                            <p className='font-bold'>Configurar tipos de cambio</p>
                        </div>}
                        <div onClick={() => setConfirmDialog({ callback: handleSignOut, title: '¿Estás seguro que deseas cerrar sesión?', cancelButton: 'Cancelar', confirmButton: 'Cerrar sesión' })} className='m-2 py-2 bg-sf-gray-extra-light flex items-center justify-center ring-inset ring-sf-gray-dark rounded-lg hover:ring-1 cursor-pointer'>
                            <p className='font-bold'>Cerrar sesión de coach</p>
                        </div>
                    </div>
                </div>
            </aside>

            <ConfirmDialog />

            <div className={`lg:pl-64 ${isContentBlocked && 'fixed h-full w-full'}`}>
                <div className='fixed lg:hidden h-fit w-full p-4 pb-2 flex bg-sf-white drop-shadow-lg'>
                    <button onClick={() => setIsContentBlocked(oldIsOpen => !oldIsOpen)} className='hover:bg-sf-gray-extra-light'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="h-8 w-8" viewBox="0 0 24 24"><path fill="currentColor" d="M4 18q-.425 0-.713-.288T3 17q0-.425.288-.713T4 16h16q.425 0 .713.288T21 17q0 .425-.288.713T20 18H4Zm0-5q-.425 0-.713-.288T3 12q0-.425.288-.713T4 11h16q.425 0 .713.288T21 12q0 .425-.288.713T20 13H4Zm0-5q-.425 0-.713-.288T3 7q0-.425.288-.713T4 6h16q.425 0 .713.288T21 7q0 .425-.288.713T20 8H4Z" /></svg>
                    </button>
                    <img src={logo} alt='logo' className='h-6 mx-4 mb-2 antialiased cursor-pointer' onClick={() => goTo('/')} />
                </div>
                <div className='fixed h-fit w-full divide-y divide-sf-white mt-14 lg:mt-0 lg:pr-64'>
                    {feedbacks.map(feedback => <div key={feedback.id} className={`h-fit w-full p-2 px-4 lg:py-4 flex justify-between text-sf-white ${feedback.level === 'success' ? 'bg-sf-green-dark' : feedback.level === 'info' ? 'bg-sf-violet-dark' : feedback.level === 'error' ? 'bg-sf-red-dark' : 'bg-sf-yellow-dark'}`}>
                        <h3>{feedback.message}</h3>
                        <svg xmlns="http://www.w3.org/2000/svg" className='h-5 w-5 cursor-pointer' onClick={() => { removeFeedback(feedback) }} viewBox="0 0 24 24"><path fill="currentColor" d="m12 13.4l-4.9 4.9q-.275.275-.7.275t-.7-.275q-.275-.275-.275-.7t.275-.7l4.9-4.9l-4.9-4.9q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l4.9 4.9l4.9-4.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7L13.4 12l4.9 4.9q.275.275.275.7t-.275.7q-.275.275-.7.275t-.7-.275L12 13.4Z" /></svg>
                    </div>)}
                </div>
                <div className='pt-16'>
                    <Outlet />
                </div>
            </div>

        </div>
    )
}
