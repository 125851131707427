import { defaultGoal } from '../model/Goal';
import { SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react';
import { Context } from '../utils/context';
import { useNavigate } from 'react-router-dom';
import { createGoal, createOperationDetail } from '../utils/api';
import Loading from '../components/Loading';
import { useCurrencyByCode } from '../hooks/CurrencyFromCode';
import Select from '../components/Select';
import { processDecimalInput } from '../utils/process-decimal-input';
import { defaultOperationDetail } from '../model/OperationDetail';
import { OperationTypes } from '../consts/OperationTypes';

export default function NewGoal(): JSX.Element {
    const { client, setOperationDetails } = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [goal, setGoal] = useState({ ...defaultGoal });
    const navigate = useNavigate();
    const { getSign } = useCurrencyByCode();

    const setUp = useCallback(async () => {
        try {
            setLoading(true);
            setGoal({
                ...defaultGoal,
                currency: client.currencies ? client.currencies[0]?.code ?? '' : '',
                status: 'pending'
            });
        } finally {
            setLoading(false);
        }
    }, [client.currencies]);

    useEffect(() => { setUp() }, [setUp]);

    function handleInputChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        setGoal(oldGoal => ({
            ...oldGoal,
            [target.name]: target.value
        }));
    }

    async function saveChanges() {
        try {
            setLoading(true);
            const newGoal = await createGoal(client.id ?? '', goal);
            const newOperationDetail = await createOperationDetail(client.id ?? '', {
                ...defaultOperationDetail,
                title: goal.title,
                description: goal.description,
                goal_id: newGoal.goal_id,
                type: OperationTypes.EXPENDITURE.key,
                status: 'ENABLED',
            });
            setOperationDetails(oldOperationDetails => [...oldOperationDetails, newOperationDetail]);
        } finally {
            navigate('/goals');
            setLoading(false);
        }
    }

    if (loading) return <Loading />

    return (
        <main>
            <article className='bg-sf-green-light'>
                <h2>Nuevo objetivo</h2>
                <div className='flex space-x-4'>
                    <input name='title' type='text' placeholder='Objetivo *' value={goal.title} onChange={handleInputChange} className='field' />
                </div>
                <div className='flex space-x-3'>
                    <div className='w-48'>
                        <Select placeholder='Moneda' name='currency' value={goal.currency} onChange={handleInputChange} options={client.currencies?.map(currency => ({ value: currency.code, alias: getSign(currency.code) }))} />
                    </div>
                    <input type='text' inputMode='decimal' name='amount' placeholder='Monto *' value={goal.amount} onChange={e => processDecimalInput(e) && handleInputChange(e)} className='field' />
                </div>
                <input name='term' type='text' placeholder='Plazo *' value={goal.term} onChange={handleInputChange} className='field' />
                <input name='description' type='text' placeholder='Descripción (opcional)' value={goal.description} onChange={handleInputChange} className='field' />
                <div className='self-end flex space-x-2 w-full justify-end'>
                    <button className="button-secondary" onClick={() => { navigate('/goals') }}>
                        Cancelar
                    </button>
                    <button className="button-primary" disabled={!goal.title || !goal.currency || !goal.amount || !goal.status || !goal.term} onClick={saveChanges}>
                        Guardar
                    </button>
                    <button className="button-secondary" disabled={!goal.title || !goal.currency || !goal.amount || !goal.status || !goal.term} onClick={() => {
                        setLoading(true);
                        createGoal(client.id ?? '', goal).finally(() => {
                            navigate('/goals');
                            setLoading(false);
                        });
                    }}>
                        Guardar sin categoría de egreso
                    </button>
                </div>
            </article>
        </main>
    )
}